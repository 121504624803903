<template>
	<div
		class="panel"
		:class="[
			isHeaderIndent? 'mt-0 pt-1' : '',
			isFooterIndent? 'mb-0 pb-1' : '',
			block.appearance? block.appearance : ''
		]"
	>
		<div
			class="container"
			:class="[
				isBrand? 'sk' : '',
			]"
		>
			<div v-if="isHeaderVisible" class="panel_header">
				<h2>{{ block.block_title }}</h2>
				<div v-if="block.description" v-html="block.description" class="description"></div>
			</div>
			<div class="items-container">
				<div class="row">
					<div
						class="col-12 pt-4 mb-4 border-top"
						:class="[
							block.columns ? 'col-md-' + 12/block.columns : 'col-md-4'
						]"
						v-if="block.items"
						v-for="(item, index) in block.items"
						v-bind:key="index"
					>
						<div class="item">
							<div v-if="item.icon" :class="['icon', item.icon]"/>
							<div class="title" v-if="item.title">{{ item.title }}</div>
							<div class="description" v-if="item.description" v-html="item.description"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import panel from '~/mixins/panel'

export default {
	name: "DraftWhyWe1",
	mixins: [panel],
	props: ["block"],
}
</script>

<style lang="scss" scoped>
	@import "~/assets/styles/components/dev/draft-whywe-1";
</style>
